import { useAppSelector } from "@/lib/redux/hooks";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Anchor } from "./styles";

import AppLink, { AppLinkProps, pageMatchesPath } from "@/components/AppLink";

export type InternalLinkProps = AppLinkProps & {
  title: string | null;
};

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { title, page, params } = props;

  const menuIsOpen = useAppSelector((state) => state.desktopMenu.isOpen);

  const { asPath } = useRouter();
  const isCurrentPage = pageMatchesPath(page, asPath);

  return (
    <AppLink page={page} params={params}>
      <Anchor
        $isActive={isCurrentPage}
        $menuIsOpen={menuIsOpen}
        aria-current={isCurrentPage ? "page" : "false"}
      >
        {title}
      </Anchor>
    </AppLink>
  );
};

export default InternalLink;
